import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Container, Typography, Link, Box, IconButton, Menu, MenuItem, Hidden, useMediaQuery } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Icon } from '@iconify/react';
import homeFill from '@iconify-icons/eva/home-fill';
import roundStreetview from '@iconify-icons/ic/round-streetview';
import roundSpeed from '@iconify-icons/ic/round-speed';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { createGAEvent, EVENT_TYPES, SECTIONS } from 'src/utils/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.common.white,
    height: '50px',
    padding: 0,
    paddingBottom: '20px',
    fontSize: '13px', // Set font size to 13px
    transition: 'top 0.5s', // Add transition effect for smoother animation
    top: 0, // Initial position at the top of the page
    width: '100%',
    position: 'fixed',
  },
  iconButton: {
    padding: 0,
    paddingRight: '10px',
    '&:hover': {
      backgroundColor: 'transparent', // Remove hover background color
    },
    '&:active': {
      backgroundColor: 'transparent', // Remove active background color
      boxShadow: 'none', // Ensure no shadow on click
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent', // Remove focus-visible background color
      boxShadow: 'none', // Ensure no shadow on focus
    },
    cursor: 'default', // Ensure default cursor
  },
  toolbar: {
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '50px',
    },
  },
  menuDesktop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  menuItem: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(3),
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:last-child': {
      marginRight: '-20px',
    },
  },
  international: {
    fontSize: '14px', // Set font size to 13px
    color: theme.palette.text.primary, // Set text color to black
  },
  centerVertically: {
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    '& .MuiPaper-root': {
      cursor: 'default', // Ensure cursor remains default for the dropdown menu
    },
    '& .MuiMenuItem-root': {
      cursor: 'default', // Ensure cursor remains default for menu items
    },
  },
}));

const MENU_LINKS_FIRST_SECTION = [
  { title: 'Our Company', icon: homeFill, href: '/#home', isHashLink: true },
  {
    title: 'Career with Streben',
    icon: roundStreetview,
    href: '/career',
    isHashLink: false,
    eventType: EVENT_TYPES.CAREER
  },
];

const MENU_LINKS_SECOND_SECTION = [
  {
    title: 'Contact',
    icon: roundSpeed,
    href: '/contact',
    isHashLink: false,
    eventType: EVENT_TYPES.CONTACT
  },
];

function TopBarZero() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const isHome = pathname === '/';
  const isPage = pathname === '/mendix';

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('lg'));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up('lg', 'xl'));
  const isSpecificRange = useMediaQuery('(min-width:1278px) and (max-width:1450px)');
  const isSpecifcRange = useMediaQuery('(min-width:1450px) and (max-width:1560px)');

  // Function to handle scrolling
  const handleScroll = () => {
    // Set the position of the TopBarZero based on the scroll position
    if (window.pageYOffset === 0) {
      anchorRef.current.style.top = '0';
    } else {
      anchorRef.current.style.top = '-50px'; // Hides the TopBarZero on scrolling down
    }
  };

  // Add event listener for scrolling when the component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const renderMenuDesktopFirstSection = (
    <Box className={classes.centerVertically} sx={{ marginLeft: '-20px' }}>
      {MENU_LINKS_FIRST_SECTION.map((link, index) =>
        link.isHashLink ? (
          <Link
            key={index}
            component={HashLink}
            to={link.href}
            variant="body2"
            color="inherit"
            underline="none"
            marginBottom="10px"
            activeClassName={classes.active}
            className={clsx(classes.menuItem, {
              [classes.isHome]: isHome || isPage
            })}
            onClick={() => createGAEvent(link.eventType, SECTIONS.TOP_MENU)}
          >
            {link.title}
          </Link>
        ) : (
          <Link
            key={index}
            component={RouterLink}
            to={link.href}
            variant="body2"
            color="inherit"
            underline="none"
            marginBottom="10px"
            activeClassName={classes.active}
            className={clsx(classes.menuItem, {
              [classes.isHome]: isHome || isPage
            })}
            onClick={() => createGAEvent(link.eventType, SECTIONS.TOP_MENU)}
          >
            {link.title}
          </Link>
        )
      )}
    </Box>
  );

  const renderMenuDesktopSecondSection = (
    <Box className={classes.menuItem}>
      {MENU_LINKS_SECOND_SECTION.map((link, index) => (
        <Link
          key={index}
          component={RouterLink}
          to={link.href}
          variant="body2"
          color="inherit"
          underline="none"
          activeClassName={classes.active}
          className={clsx(classes.menuItem, {
            [classes.isHome]: isHome || isPage
          })}
          onClick={() => createGAEvent(link.eventType, SECTIONS.TOP_MENU)}
        >
          {link.title}
        </Link>
      ))}
      <IconButton
        ref={anchorRef}
        onClick={handleMenuOpen}
        disableRipple // Disable the ripple effect on click
        className={classes.iconButton}
      >
        <Typography variant="body2" sx={{ fontSize: '14px', color: "#212B36", paddingBottom: "6px", fontWeight: "500" }}>
          International
        </Typography>
        <ExpandMoreIcon sx={{ color: "#212B36", paddingBottom: "3px", marginTop: "-2px" }} />
      </IconButton>

      <Menu
        anchorEl={anchorRef.current}
        open={openMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            position: 'absolute', // Make the dropdown position absolute
            top: '50px', // Adjust the vertical position as needed
            left: '50px', // Adjust the horizontal position as needed
            marginTop: '0px',
            paddingLeft: '9px',
            paddingRight: '29px',
            marginLeft: isMediumScreen ? '-50px' : '-40px',
            marginLeft:
              isSpecifcRange ? '-100px' :
              isSpecificRange ? '-60px' :
              isMediumScreen ? '-50px' :
              isLargeScreen ? '-80px' :
              isXLargeScreen ? '-180px' :
              '0px',
            backgroundColor: '#f4f4f4',
            boxShadow: 'none',
            transition: 'none',
          },
        }}
        className={classes.menu}
      >
        {['India', 'USA', 'UAE', 'UK'].map((option, index) => (
          <MenuItem
            key={index}
            onClick={handleMenuClose}
            sx={{
              '&:hover': {
                backgroundColor: 'inherit', // Ensure no hover effect
              },
              '&:focus': {
                backgroundColor: 'inherit', // Ensure no focus effect
              },
              '&:active': {
                backgroundColor: 'inherit', // Ensure no active effect
              },
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );

  return (
    <AppBar className={classes.root} ref={anchorRef}>
      <Toolbar className={classes.toolbar}>
        <Container maxWidth="lg">
          <Box>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {renderMenuDesktopFirstSection}
              {renderMenuDesktopSecondSection}
            </div>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default TopBarZero;
  
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { APIUrl } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  projectType: [],
  fullName: '',
  contact: '',
  email: '',
  description: ''
};

const slice = createSlice({
  name: 'estimateProject',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Reset Estimate Request
    getEtimateSubmitSuccess(state, action) {
      state.isLoading = false;
      Object.keys(action.payload).forEach((t) => {
        state[t] = '';
      });
    }
  }
});
export const { updateProjectForm, startLoading } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const submitEstimateRequest = function createAsyncThunk(
  data,
  setSubmitting,
  resetForm,
  setSubmitted
) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${APIUrl}/estimates`, data);
      if (response && response.status === 200) {
        setSubmitting(false);
        resetForm({});
        setSubmitted(true);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

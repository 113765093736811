import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { APIUrl } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  showForm: false
};

const slice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    toggleContactUsForm(state, action) {
      console.log(action.payload);
      state.showForm = action.payload;
    },
    updateContactForm(state, action) {
      const key = Object.keys(action.payload)[0];
      state[key] = action.payload[key];
    },
    resetForm(state, action) {
      state.isLoading = false;
      Object.keys(action.payload).forEach((t) => {
        state[t] = '';
      });
    }
  }
});
export const { toggleContactUsForm, updateContactForm } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function showContactUsFOrm(show) {
  return (dispatch) => {
    dispatch();
  };
}

export const submitContactUsForm = function createAsyncThunk(
  data,
  setSubmitting,
  resetForm,
  setSubmitted
) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${APIUrl}/contacts`, data);
      if (response && response.status === 200) {
        setSubmitting(false);
        setSubmitted(true);
        resetForm();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import notificationsReducer from './slices/notifications';
import estimateProject from './slices/estimateProject';
import contactUs from './slices/contactUs';
import caseStudies from './slices/caseStudies';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  notifications: notificationsReducer,
  product: persistReducer(productPersistConfig, productReducer),
  estimateProject: estimateProject,
  contactUs: contactUs,
  caseStudies: caseStudies
});

export { rootPersistConfig, rootReducer };

import clsx from "clsx";
import { Icon } from "@iconify/react";
import Logo from "src/components/Logo";
import React, { useState, useRef, useEffect } from "react";
import { Close as CloseIcon, Menu as MenuIcon } from "@material-ui/icons";
import menu2Fill from "@iconify-icons/eva/menu-2-fill";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createGAEvent, EVENT_TYPES, SECTIONS } from "src/utils/analytics";
import {
  NavLink as RouterLink,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { makeStyles, alpha, styled } from "@material-ui/core/styles";
import {
  Box,
  AppBar,
  Toolbar,
  Container,
  ListItemIcon,
  useTheme,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  useMediaQuery,
  ListItemText,
} from "@material-ui/core";
import { MIconButton } from "src/theme";
import { HashLink } from "react-router-hash-link";
import { useDispatch } from "react-redux";
import PopoverMenu from "src/components/PopoverMenu";
import { Hidden } from "@material-ui/core";
import { BorderTop } from "@material-ui/icons";
import { SearchIcon } from "@material-ui/icons";
import searchIcon from "./search.png";
import { shadow } from "deck.gl";

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.common.white,
    height: "70px",
    borderBottom: "0.5px solid #63636333",
    borderTop: "0.5px solid #63636333",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0) !important",
    transition: "transform 0.3s ease-in-out",
    transform: "translateY(0)",
    "&.hide": {
      transform: "translateY(-100%)",
    },
    [theme.breakpoints.down("sm")]: {
      borderBottom: "0px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "none",
    },
  },
  toolbar: {
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '50px',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuDesktop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft:"-20px",
    marginRight: theme.spacing(2),
  },
  searchIcon: {
    marginLeft: 'auto',
    paddingRight: '15px',
    marginRight: "-20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "-50px",
    },

  },
  searchImg: {
    height: '13px',
    width: '13px',
  },
  menuMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft:"-27px",
  },
  logoWrapper:{
    paddingRight:0,
  },
}));
 

function TopBar() {
  const ContentStyle = styled("div")(({ theme }) => ({
    marginTop: 0,
  }));
  const theme = useTheme();
  // const isSmallScreen = useMediaQuery('(max-width: 400px)');
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [openProductsMenu, setOpenProductsMenu] = useState(false);
  const [openIndustriesMenu, setOpenIndustriesMenu] = useState(false);
  const [openSolutionsMenu, setOpenSolutionsMenu] = useState(false);
  const [openPartnersMenu, setOpenPartnersMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);


  const handleClose = () => {
    console.log("sidebar closed");
    history.goBack(); // Navigate back to the previous page
  };

  const handleProductsMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenProductsMenu(true);
  };
  

  const handleIndustriesMenuOpen = () => {
    setOpenIndustriesMenu(true);
  };

  const handleSolutionsMenuOpen = () => {
    setOpenSolutionsMenu(true);
  };

  const handlePartnersMenuOpen = () => {
    setOpenPartnersMenu(true);
  };

  const handleMenuClose = () => {
    setOpenProductsMenu(false);
    setOpenIndustriesMenu(false);
    setOpenSolutionsMenu(false);
    setOpenPartnersMenu(false);
    setOpenMenu(false);
  };

  const dispatch = useDispatch();
  const history = useHistory(); //
  const classes = useStyles();
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [hideOnScroll, setHideOnScroll] = useState(false); // State to track if the bar should be hidden
  const isHome = pathname === "/";
  const isPage = pathname === "/mendix";
  const menuOptionsIndustries = [
    { label: "Shipping", path: "/Shipping" },
    { label: "Logistics and Supply Chain", path: "/LogisticsSupplyChain" },
    { label: "BFSI", path: "/BFSI" },
    { label: "Retail", path: "/Retail" },
    { label: "Manufacturing", path: "/Manufacturing" },
    { label: "Governments", path: "/Governments" },
  ];
  

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };
  const MenuComponent = () => {
    const history = useHistory();
    handleClose();
  };

  const handleMenuItemClick = (path) => {
    history.push(path);
    setOpenProductsMenu(false);
    setOpenIndustriesMenu(false);
    setOpenSolutionsMenu(false);
    setOpenPartnersMenu(false);
    setOpenMenu(false); // Close the menu after selection
  };
  
  const navigateToLabelease = () => {
    history.push("/Labelease");
    
  };
  

  // Function to handle scroll event
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const shouldHide = currentScrollY > 0; // Hide bar if scrolled down
    setHideOnScroll(shouldHide);
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 600); // Adjust the threshold as needed
    };
    checkScreenSize(); // Call the function initially
    window.addEventListener("resize", checkScreenSize);
    // Add event listener for resize
    return () => window.removeEventListener("resize", checkScreenSize); // Clean up the event listener
  }, []); // Empty dependency array means the effect runs only once

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const menuOptions = [

    //As Per Request Comented 
    // { label: "LogistiX", path: "/LogistiX" }, 
    { label: "Label Ease", path: "/Labelease" },
    { label: "Asset Tracker", path: "/Assettracker" },
    { label: "People Integrator", path: "/PeopleIntegrator" },
  ];

  const renderMenuDesktop = (
    <Hidden smDown>
      <div className={classes.menuDesktop}>
        <Link to="/">
          <div className={classes.logoWrapper}>
            <Logo />
          </div>
        </Link>
        <div>
          <IconButton
            ref={anchorRef}
            onClick={handleProductsMenuOpen}
            sx={{
              paddingRight: "10px",
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&:active": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                color: "#212B36",
                paddingBottom: "3px",
                fontWeight: "500",
                paddingLeft: "20px",
              }}
            >
              Products
            </Typography>
            <ExpandMoreIcon
              sx={{ color: "#212B36", paddingBottom: "3px", marginTop: "-2px" }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorRef.current}
            open={openProductsMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "start",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "start",
            }}
            PaperProps={{
              style: {
                marginLeft: "-300px",
                marginTop: "0px",
                backgroundColor: "#010E3D",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            {menuOptions.map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick(option.path)}
                style={{  fontSize: "11px",
                  color: "white",
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: "inherit",
                  },
                  "&:focus": {
                    backgroundColor: "none",
                  },
                  "&:active": {
                    backgroundColor: "inherit",
                  },
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div>
  <IconButton
    ref={anchorRef}
    onClick={handleIndustriesMenuOpen}
    sx={{
      paddingRight: "10px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:active": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    }}
  >
    <Typography
      variant="body2"
      sx={{
        fontSize: "14px",
        color: "#212B36",
        paddingBottom: "3px",
        fontWeight: "500",
      }}
    >
      Industries
    </Typography>
    <ExpandMoreIcon
      sx={{ color: "#212B36", paddingBottom: "3px", marginTop: "-2px" }}
    />
  </IconButton>
  <Menu
    anchorEl={anchorRef.current}
    open={openIndustriesMenu}
    onClose={handleMenuClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "start",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "start",
    }}
    PaperProps={{
      style: {
        marginLeft: "-250px",
        marginTop: "0px",
        backgroundColor: "#010E3D",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      },
    }}
  >
    {menuOptionsIndustries.map((option, index) => (
      <MenuItem
        key={index}
        onClick={() => handleMenuItemClick(option.path)}
        style={{
          fontSize: "11px",
          color: "white",
          cursor: "default",
          "&:hover": {
            backgroundColor: "inherit",
          },
          "&:focus": {
            backgroundColor: "none",
          },
          "&:active": {
            backgroundColor: "inherit",
          },
        }}
      >
        {option.label}
      </MenuItem>
    ))}
  </Menu>
</div>;
        <div>
      {/* Add the IconButton and Menu */}
      <IconButton
    ref={anchorRef}
    onClick={handleSolutionsMenuOpen}
    sx={{
      paddingRight: '10px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:active': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }}
  >
    <Typography
      variant="body2"
      sx={{
        fontSize: '14px',
        color: '#212B36',
        paddingBottom: '3px',
        fontWeight: '500',
      }}
    >
      Solutions
    </Typography>
    <ExpandMoreIcon
      sx={{ color: '#212B36', paddingBottom: '3px', marginTop: '-2px' }}
    />
  </IconButton>
  <Menu
    anchorEl={anchorRef.current}
    open={openSolutionsMenu}
    onClose={handleMenuClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'start',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'start',
    }}
    PaperProps={{
      style: {
        marginLeft: '-100px',
        marginTop: '0px',
        backgroundColor: '#010E3D',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      },
    }}
  >
    {[
      { label: 'Robotic Process Automation', path: '/Robot' },
      { label: 'Application Modernization', path: '/ApplicationModernization' },
      { label: 'Low Code Development', path: '/LowCodeDevelopment' },
      { label: 'Mobile Application Development', path: '' },
    ].map((option, index) => (
      <MenuItem
        key={index}
        onClick={() => handleMenuItemClick(option.path)}
        style={{  fontSize: "11px",
          color: "white",
          cursor: "default",
          "&:hover": {
            backgroundColor: "inherit",
          },
          "&:focus": {
            backgroundColor: "none",
          },
          "&:active": {
            backgroundColor: "inherit",
          },
        }}
       
      >
        {option.label}
      </MenuItem>
    ))}
  </Menu>
</div>
<div>
  {/* Add the IconButton and Menu for Partners */}
  <IconButton
    ref={anchorRef}
    onClick={handlePartnersMenuOpen}
    sx={{ 
      paddingRight: "10px",
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:active': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }}
  >
    <Typography
      variant="body2"
      sx={{
        fontSize: "14px",
        color: "#212B36",
        paddingBottom: "3px",
        fontWeight: "500",
      }}
    >
      Partners
    </Typography>
    <ExpandMoreIcon
      sx={{ color: "#212B36", paddingBottom: "3px", marginTop: "-2px" }}
    />
  </IconButton>
  <Menu
    anchorEl={anchorRef.current}
    open={openPartnersMenu}
    onClose={handleMenuClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "end",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "end",
    }}
    PaperProps={{
      style: {
        marginTop: "0px",
        backgroundColor: "#010E3D",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      },
    }}
  >
    {[
      { label: "Mendix", path: "" },
      { label: "Zebra", path: "" },
      { label: "Solace", path: "/Solace" },
    ].map((option, index) => (
      <MenuItem
        key={index}
        onClick={() => handleMenuItemClick(option.path)}
        style={{ fontSize: "11px", color: "white" }}
      >
        {option.label}
      </MenuItem>
    ))}
  </Menu>
</div>
      </div>
      {/* <div className={classes.searchIcon}>
        <img src={searchIcon} alt="Search Icon" className={classes.searchImg} />
      </div> */}
    </Hidden>
  );
  const renderMenuMobile = (
    <Hidden mdUp>
      <div className={classes.menuMobile}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          px={0}
        >
          <IconButton
            onClick={() => history.push("/sidebar")}
            className={classes.iconButton}
          >
            {pathname === "/sidebar" ? (
              <CloseIcon onClick={handleClose} />
            ) : (
              <MenuIcon />
            )}
          </IconButton>

          <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Link to="/">
              <Logo
                style={{ width: "75px", height: "auto", marginRight: "16px" }}
              />
            </Link>
          </Box>

          {/* <Box className={classes.searchIcon}>
            <img
              src={searchIcon}
              alt="Search Icon"
              className={classes.searchImg}
            />
          </Box> */}
        </Box>
      </div>
    </Hidden>
  );

  return (
    <AppBar
      className={clsx(classes.root, { hide: hideOnScroll })}
      style={{ marginTop: hideOnScroll || isSmallScreen ? 0 : "50px" }}
    >
      <Toolbar className={classes.toolbar}>
        <Container maxWidth="lg" className={classes.container}>
          <Box className={classes.logoContainer}>{renderMenuDesktop}</Box>
          {renderMenuMobile}
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
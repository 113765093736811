import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { motion } from "framer-motion";
import { varFadeInRight } from "src/components/Animate";
import ConceptImg from "./concept.png";
import LazyImage from "react-lazy-blur-image";


const useStyles = makeStyles((theme) => ({
  parent: {
    padding: theme.spacing(2),
    width: "100%",
    maxWidth: "1380px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    textAlign: "center",
    padding: theme.spacing(4),
    margin: "0 auto",
  },
  subtitle: {
    fontFamily: "Be Vietnam",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: 1.6,
  },
  title: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontFamily: "Russo One",
    fontSize: "28px",
    fontWeight: 400,
  },
  descriptionContainer: {
    padding: theme.spacing(4),
  },
  imageContainer: {
    padding: theme.spacing(4),
    textAlign: "center", 
  },
  description: {
    fontFamily: "Be Vietnam",
    fontSize: "14px",
    fontWeight: 400,
  },
  divider: {
    backgroundColor: "#ccc",
    height: "2px",
    width: "100%",
    maxWidth: "1380px",
  },
}));

function LowCodeDevelopmentApply() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <div className={classes.parent}>
      <Container maxWidth="lg" sx={{ marginTop: isMobile ? "20px" : "50px" }}>
        <motion.div variants={varFadeInRight}>
          <Typography className={classes.subtitle}>
            Low Code Development
          </Typography>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={10} className={classes.contentContainer}>
              <Typography variant="h2" className={classes.title}>
                What is Low Code Development?
              </Typography>
              <div className={classes.descriptionContainer}>
                <Typography variant="body1" className={classes.description}>
                  Low Code Development is a modern approach to software
                  development that significantly <br />
                  reduces the amount of hand-coding required. This methodology
                  leverages visual interfaces <br />
                  with simple logic and drag-and-drop features, enabling both
                  professional developers and <br />
                  business users to build applications quickly and efficiently.
                </Typography>
              </div>
              <div  className={classes.imageContainer}>
              <LazyImage
                    placeholder={"/static/images/HRFeedback.jpg?blur=10"} // Adjust placeholder URL with blur effect
                    uri={ConceptImg}
                    render={(src, style) => (
                      <img
                        src={src}
                        style={{
                          ...style,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                        alt="Concept Image"
                      />
                    )}
                  />
              </div>
            </Grid>
          </Grid>
        </motion.div>
        <Divider className={classes.divider} />
      </Container>
    </div>
  );
}

export default LowCodeDevelopmentApply;

import React from 'react';
import TopBar from './TopBar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core'; // Import Hidden component
import TopBarZero from './TopBarZero';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: { 
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // marginRight: "0px",
    
    
  },
  content: { 
    height: '100%', 
    flexGrow: 1, 
    display: 'flex', 
    flexDirection: 'column',
    marginTop: theme.spacing(7),
    
    
     // Adjust margin-top according to your needs
  },
}));

// ----------------------------------------------------------------------

HomeLayout.propTypes = {
  children: PropTypes.node
};

function HomeLayout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Hide TopBarZero on small/mobile screens */}
     <Hidden smDown>
        <TopBarZero />
      </Hidden> 

      <TopBar />
      
      <div className={classes.content}>{children}</div>
    </div>
  );
}

export default HomeLayout;

import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  caseStudies: []
};

const slice = createSlice({
  name: 'caseStudies',
  initialState,
  reducers: {
    resetForm(state, action) {
      state.isLoading = false;
      Object.keys(action.payload).forEach((t) => {
        state[t] = '';
      });
    },
    storeCaseStudies(state, action) {
      state.caseStudies = action.payload;
    }
  }
});
export const { storeCaseStudies } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

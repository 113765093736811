import { googleAnalyticsConfig } from 'src/config';

// ----------------------------------------------------------------------

export const EVENT_TYPES = {
  GET_AN_ESTIMATE: 'GetAnEstimate',
  CONTACT_US: 'ContactUs',
  SERVICES: 'Services',
  TECHNOLOGY: 'Technology',
  ABOUT_US: 'AboutUs',
  CASE_STUDIES: 'CaseStudies',
  MENDIX: 'Mendix',
};

export const SECTIONS = {
  HERO: 'Hero',
  FOOTER: 'Footer',
  TOP_MENU: 'TopMenu'
};

const setup = (...args) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  if (!window.gtag) {
    return;
  }
  window.gtag(...args);
};

const track = {
  pageview: (props) => {
    setup('config', googleAnalyticsConfig, props);
  },
  event: (type, props) => {
    setup('event', type, props);
  }
};

export const createGAEvent = (eventType, section) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: eventType, section: section });
};

export default track;
